import * as React from "react"
import PreviewArticleList from "./article/preview-article-list"
import TitlePageComponent from "./title-page"
import Layout from "./layout/layout"
import { graphql } from 'gatsby'
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import SEOComponent from './seo'
import RssFeedIcon from '@mui/icons-material/RssFeed';
import { Pagination } from "@mui/material"

const NewsPage = ({ data, pageContext }) => {
  const { t } = useTranslation();
  const siteContent = data.siteContent
  const siteURL = data.site.siteMetadata.siteUrl
  const language = data.locales.edges[0].node.language
  
  const { navigate} = useI18next();
  const handleChange = (event, value) => {
    if(value === 1) value = ""
    navigate("/commitment/"+value)
  };
  return (
    <main>
      <Layout siteContent={siteContent}>
        <SEOComponent title={t("menu_news")} />
        <TitlePageComponent title={t("menu_news")} description={siteContent.actualites.description}>
        </TitlePageComponent>
        <PreviewArticleList articles={data.newsArticles.nodes}></PreviewArticleList>
        <div className="flex flex-col container items-center">
        {
          pageContext.numberOfPages > 1 &&
            <Pagination count={pageContext.numberOfPages } page={pageContext.pageNumber +1} onChange={handleChange} size="large" color="secondary" />
        }
          </div>
        
      </Layout>
    </main>
  )
}
export default NewsPage;

export const query = graphql`
query($language: String!, $skip: Int!, $limit: Int!) {
  locales: allLocale(filter:{language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  site {
    siteMetadata {
      siteUrl
    }
  }
  siteContent(language: {eq: $language}) {
    editionYear
    programYear
    actualites {
      description
    }
    language
    cookieURL
    personalDataURL
    banner
  }
  newsArticles: allArticle(
    skip: $skip 
    limit: $limit,
    sort: {fields: datePublished, order: DESC},
    filter: {
      typeArticle: {eq: news}, 
      language: {eq: $language}
    }
  ) {
    nodes {
      title
      slug
      shortDescription
      thumbnailImage
      categories
    }
  }
}
`