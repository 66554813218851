import parse from "../utils/html-parser.options"
import * as React from "react"
import '../styles/title.css'

const TitlePageComponent = ({ title, description, children }) => {
    return (
        <section className="flex flex-col container mx-auto px-2 items-center text-center my-20">
            <h1 className="text-7xl font-black m-0 text-center uppercase lg:text-4xl">{title}</h1>
            <div className="mt-10 text-2xl lg:text-xl">
                {parse(description)}
            </div>
            {children}
        </section>
    )
}

export default TitlePageComponent;